import { Duration } from "luxon";
import { isNullOrWhiteSpace } from "./stringUtility";

export function parseIso(isoDuration) {
    if (isNullOrWhiteSpace(isoDuration)) {
        return null;
    }
    return Duration.fromISO(isoDuration);
}

export function formatDuration(input) {
    if (isNullOrWhiteSpace(input)) {
        return null;
    }
    //if the input is a number
    if (!isNaN(input) && !isNaN(parseFloat(input))) {
        return formatNumberAsDuration(input);
    }
    let components = input.split(":").map(e => parseInt(e));
    let durationObject = {
        hours: components[0],
        minutes: components[1]
    };
    if (components.length === 3) {
        durationObject.seconds = components[2];
    }
    return Duration
        .fromObject(durationObject)
        .toFormat("h:mm");
}

export function formatNumberAsDuration(input) {
    if (!input.includes(".") && Number.isInteger(Number(input)) && parseInt(input) >= 10) {
        // if the input is an integer and greater than 10, treat the integers as minutes
        let minutes = parseInt(input);
        let milliseconds = minutes * 60 * 1000;
        return Duration
            .fromMillis(milliseconds)
            .toFormat("h:mm");
    }
    // if the input is a decimal, treat the value as hours
    let hours = parseFloat(input);
    let milliseconds = hours * 60 * 60 * 1000;
    return Duration
        .fromMillis(milliseconds)
        .toFormat("h:mm");
}

// Assumes a duration string in the format h:mm:ss
export function parseDuration(duration) {
    if (isNullOrWhiteSpace(duration)) {
        return null;
    }
    let components = duration.split(":").map(e => parseInt(e));
    let durationObject = {
        hours: components[0],
        minutes: components[1],
        seconds: components[2]
    };
    return Duration.fromObject(durationObject);
}
