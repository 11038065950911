<template>
    <app-text-field
        type="number"
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required"
        v-on="$listeners" />
</template>

<script>
import formFieldMixin from "./formFieldMixin";

export default {
    types: [
        "int",
        "decimal",
        "double",
        "single"
    ],
    mixins: [formFieldMixin],
    props: {
        value: {
            type: Number,
            default: null,
        },
    }
};
</script>
