<template>
    <div class="d-flex flex-nowrap align-center justify-center app-date-time-field">
        <app-text-field
            v-model="displayDate"
            v-bind="$attrs"
            :label="label"
            :required="required"
            readonly
            @click="edit"
            @keydown="onKeyDown" />

        <v-dialog :key="refreshKey" v-model="dialog" max-width="500px">
            <v-form ref="editForm" @submit="onSave" @submit.prevent>
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ label }}</span>
                    </v-card-title>

                    <v-card-text>
                        <app-date-field
                            v-model="date"
                            autofocus
                            label="Date"
                            :required="required" />

                        <app-time-field v-model="time" label="Time" outlined />

                        <app-offset-field
                            v-model="offset"
                            label="UTC Offset"
                            :required="required" />
                    </v-card-text>

                    <v-card-actions>
                        <!-- 
                            The ok button is first to make sure its the first button we can tab to.
                            We use the "order-last" flex helper to place it right-most.
                        -->

                        <v-btn
                            class="order-last"
                            type="submit"
                            color="quaternary"
                            text
                            :disabled="readonly || !isValid">
                            Ok
                        </v-btn>

                        <v-btn
                            color="quinary"
                            text
                            :disabled="readonly"
                            @click="clear">
                            Clear
                        </v-btn>

                        <div class="flex-grow-1" />
                        
                        <v-btn
                            color="quaternary"
                            text
                            @click="dialog = false">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { DateTime, Duration } from "luxon";
import { parseIso, now, toIsoOffset } from "@/services/dateUtility";
import { isEmpty } from "@/services/objectUtility";
import { format } from "@/formatting/dateTimeOffsetFormatter";

// Consider the following represents a full date time with an offset:
// 2019-09-28T18:00:00+08:00

export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "Date and Time"
        }
    },
    data() {
        return {
            date: null,
            time: null,
            offset: null,
            dialog: false,
            refreshKey: 0
        };
    },

    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        dateTime() {
            if(isEmpty(this.valueLocal))
            {
                return null;
            }
            return parseIso(this.valueLocal)
        },
        displayDate() {
            if(this.dateTime == null) {
                return "";
            }

            return format(this.dateTime);
        },
        isoDate() {
            if (!this.isValid) {
                return null;
            }

            let hasTime = isEmpty(this.time);
            let date = DateTime.fromISO(this.date).toISODate();
            let time = hasTime ? "00:00:00" : Duration.fromISOTime(this.time).toISOTime();
            let offset = this.offset;

            return `${date}T${time}${offset}`;
        },
        isValid() {
            return !isEmpty(this.date) && this.offset != null;
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (isEmpty(value)) {
                    value = now().toISO();
                }

                let dateTime = parseIso(value);
                this.date = dateTime.set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO();
                this.time = dateTime.toFormat("HH:mm");
                this.offset = toIsoOffset(dateTime.offset);
            },
        },
    },
    
    methods: {
        onSave() {
            if(!this.isValid) {
                return;
            }
            this.valueLocal = this.isoDate;
            this.dialog = false;
        },
        edit() {
            if (this.readonly) {
                return;
            }
            this.refreshKey++;
            this.dialog = true;
        },
        clear() {
            this.dialog = false;
            this.valueLocal = null;
        },
        onKeyDown(e) {
            let handledKeys = ["ArrowDown", "ArrowUp", "Enter", "Space" ];

            if(handledKeys.includes(e.key))
            {
                this.edit();
                e.preventDefault();
            }
        },
    },
};
</script>

<style scoped> 
.app-date-time-field {
    /* Wide enough for 12/29/2022, 10:48 PM UTC+14 */
    min-width: 250px;
}
</style>
