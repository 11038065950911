import Vue from 'vue'
import { camelToKebabCase } from '@/services/stringUtility'

// Grab all the App*.vue files in this folder (exluding subfolders).
const requireComponent = require.context('.', false, /^.\/(App)[\w-]+\.vue$/);

// Register these with Vue.component so we don't need to import them everwhere.
requireComponent.keys().forEach((filePath) => {
    const config = requireComponent(filePath);
    const name = camelToKebabCase(filePath.slice(2, -4));
    Vue.component(name, config.default || config);
})