import { getLocale, getTimeZone } from "@/services/localisation";
import { DateTime } from "luxon"

export const types = [
    "datetimeoffset"
];

function offsetMatchesCurrentTimeZone(dateTime) {
    let localDateTime = dateTime
        .setZone(getTimeZone());
        
    return dateTime.offset === localDateTime.offset;
}

function formatOffset(offset) {
    if (offset === 0) {
        return "UTC";
    }

    let hours = parseInt(Math.abs(offset / 60));
    let minutes = Math.abs(offset % 60);
    let prefix = offset > 0 ? "+" : "-";

    if (minutes === 0) {
        minutes = "";
    } else if (minutes < 10) {
        minutes = ":0" + minutes;
    } else {
        minutes = ":" + minutes;
    }

    return "UTC" + prefix + hours + minutes;
}

export function format(value) {
    if (value == null) { 
        return "";
    }

    let dateTime = DateTime
        .fromISO(value, { setZone: true })
        .setLocale(getLocale());

    const noTime = (
        dateTime.hour === 0 &&
        dateTime.minute === 0 &&
        dateTime.second === 0 &&
        dateTime.millisecond === 0);

    const format = noTime ?
        DateTime.DATE_SHORT :
        DateTime.DATETIME_SHORT;
   
    let result = dateTime
        .toLocaleString(format);
    
    // Only format the offset if it's different from the user's current time zone.
    if(offsetMatchesCurrentTimeZone(dateTime)) {
        return result;
    }

    return `${result} ${formatOffset(dateTime.offset)}`;
}
