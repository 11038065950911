import {
    buildCellOptions,
    getCellComponent,
    getHeaders,
    getTableData,
    applyForeignKeySorting
} from "@/features/schemas/services/tableService";

import { canEdit, canViewItem } from '@/features/schemas/services/schemaApi';
import { equal, and } from "@/features/schemas/services/schemaFiltering";
import schemaMixin from "./schemaMixin";
import { hasProperty } from '@/services/objectUtility';

export default {
    mixins: [ schemaMixin ],
    props: {
        filter: {
            type: Object,
            default: () => { }
        },
        fixedValues: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            entityHeaders: [],
            options: {},
            tableData: {
                items: [],
                total: 0,
                lookups: {}
            },
            hasExtraActions: false
        };
    },
    computed: {
        headers() {
            if (!(this.canEdit || this.canView || this.hasExtraActions)) {
                return this.entityHeaders;
            }

            return this.entityHeaders.concat({
                text: "",
                value: "actions",
                sortable: false,
                class: "action-cell"
            });
        },
        fieldKeys() {
            return this.entityHeaders.map(h => h.value);
        },
        fixedFields() {
            return Object.keys(this.fixedValues ?? {});
        },
        requestModel() {
            const fixedFilters = this
                .fixedFields
                .map(f => equal(this.entityKey, f, this.fixedValues[f]));

            const filters = [...fixedFilters, this.filter].filter(f => f != null);
            const filter = filters.length ? and(filters) : null;

            let options = this.options;
            if (options) {
                options.sortBy = applyForeignKeySorting(this.entityKey, options.sortBy);
            }

            return { filter, ...options };
        },
        canEdit() {
            // We can check for editability by seeing if there's a listener for the edit event.
            return canEdit(this.entityKey) && !!this.$listeners?.edit;
        },
        canView() {
            return canViewItem(this.entityKey);
        }
    },
    watch: {
        entityKey: {
            immediate: true,
            handler(value) {
                this.entityHeaders = value ?
                    getHeaders(value).filter(h => !this.fixedFields.includes(h.value)) :
                    [];
            }
        },
        requestModel: {
            immediate: true,
            async handler() {
                await this.refresh();
            }
        }
    },
    mounted() {
        this.hasExtraActions = hasProperty(this.$scopedSlots, "extra-actions");
    },
    methods: {
        getCellComponent(fieldKey) {
            return getCellComponent(this.entityKey, fieldKey);
        },
        getCellOptions(fieldKey) {
            return buildCellOptions(this.tableData, fieldKey);
        },
        async refresh() {
            if (!this.options?.take) {
                return;
            }

            this.tableData = await getTableData(this.entityKey, this.requestModel, true);
        },
        viewItem(item) {
            this.$router.push({
                name: this.entityKey,
                params: { itemId: item.id }
            });
        }
    },
}