import { getEntity } from "./schemaProvider";
import { getTableData } from "./tableService";
import { getLabelFields, getLabel } from "./labeller";
import { buildSearchFilter } from "./searchFilterBuilder";
import { and } from "@/features/schemas/services/schemaFiltering";

export async function search(entityKey, searchText, options) {
    let fields = getLabelFields(entityKey);

    if (!fields.length) {
        throw `Unable to search ${entityKey} as there are no suitable fields available.`;
    }
    let filter = buildSearchFilter(fields, searchText);
    if (options?.filter != null) {
        filter = and([filter, options.filter])
    }
    let model = {
        filter,
        sortBy: fields[0].key,
        take: 30
    };
    if (options?.sortBy != null) {
        model.sortBy = options.sortBy;
        model.direction = options.direction;
    }

    let result = await getTableData(entityKey, model, options?.includeLookups);

    if (options?.includeLabels) {
        result.items.forEach(item => item.label = getLabel(entityKey, item));

        result.headers.push({
            value: "label",
            text: getEntity(entityKey).singleTitle
        });
    }

    return result;
}

export default {
    search
}
