<template>
    <v-app>
        <template v-if="isLoaded">
            <the-alert />
            <the-app-bar v-if="isLoggedIn" />
            <v-main class="main">
                <router-view class="router-container" />
            </v-main>
        </template>
        <template v-else>
            <div class="loading d-flex align-center justify-center flex-grow-1">
                <v-progress-circular
                    :size="70"
                    :width="7"
                    indeterminate
                    color="tertiary" />
            </div>
        </template>
    </v-app>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
    components: {
        theAlert: () => import("@/components/TheAlert"),
        theAppBar: () => import("@/components/TheAppBar")
    },

    computed: {
        ...mapState({
            isLoggedIn: s => s.authentication.isLoggedIn,
            user: s => s.authentication.user,
        }),

        ...mapGetters({ 
            isLoaded: "load/isLoaded" 
        })
    },

    async mounted() {
        this.$router.onReady(this.onStartup)
    },

    methods: {
        ...mapActions({ 
            onStartup: "load/onStartup" 
        })
    }
};
</script>

<style lang="scss">
    @import "assets/style/theme.scss";

    #app {
        background-color: $color-background;
    }

    .loading {
        background-color: $color-background;
    }

    .router-container {
        width: 100%;
        height: 100%;
        padding: 10px;
        background-color: $color-background;
    }

    .relative {
        position: relative;
    }
</style>

<style lang="scss" scoped>
    // v-main dynamically calculates some top padding after the page displays. Here we are 
    // hardcoding the results of this calculation. This fixes issues with apex charts, where it 
    // calculates the height of the chart before the v-main padding is determined. This also
    // prevents the whole page popping down by 48 pixels.
    .main {
        padding-top: 48px !important;
    }
</style>
