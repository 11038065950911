<template>
    <div>
        {{ display }}
    </div>
</template>

<script>
import { getLabel } from "@/features/schemas/services/labeller.js";

export default {
    types: [
        "entity"
    ],
    props: {
        value: {
            type:  Number,
            default: () => null,
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        display() {
            let lookup = this.options?.lookup;
            if(!lookup || this.value == null) 
            {
                return "";
            }
            
            let item = lookup[this.value.toString()];
            return getLabel(this.options.entityKey, item);
        }
    }
};
</script>