import store from "@/store/store"

export function getEntity(entityKey) {
    return store.getters["schema/getEntity"](entityKey);
}

export function getEnums() {
    return store.getters["schema/enums"];
}

export function getEnum(enumKey) {
    return store.getters["schema/getEnum"](enumKey);
}

export function getEndpoint(entityKey, endpointKey) {
    const entity = getEntity(entityKey);
    return entity.endpoints[endpointKey];
}

export function getQueryField(entityKey, fieldKey) {
    return store.getters["schema/getQueryField"](entityKey, fieldKey);
}

export function getDependants(entityKey) {
    const entities = store.getters["schema/entities"];

    // get an array of { entity, field } for fields which depend on the given entityKey.
    return Object.values(entities)
        .map(entity => Object
            .values(entity.queryFields)
            .filter(field => field.dependsOn?.type === entityKey)
            .map(field => ({ entity, field })))
        .flat();
}

export function getType(entityKey, fieldKey) {
    const entity = getEntity(entityKey);
    const field = entity.queryFields[fieldKey];
    return field.type;
}