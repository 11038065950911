<template>
    <div>
        <v-card v-if="item">
            <v-card-title>
                {{ entity.singleTitle }}
            </v-card-title>
            <v-card-text>
                <div v-for="field in fields" :key="field.key" class="mb-2">
                    <label>
                        {{ field.title }}
                    </label>
            
                    <component
                        :is="getCellComponent(field.key)"
                        :key="field.key"
                        :options="getCellOptions(field.key)"
                        :value="item[field.key]" />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { 
    getCellComponent, 
    buildCellOptions,
    getTableData 
} from "@/features/schemas/services/tableService";

import { equal } from "@/services/filtering";
import schemaMixin from '@/features/schemas/mixins/schemaMixin'

export default {
    mixins: [schemaMixin],

    props: {
        itemId: {
            type: Number,
            default: null
        }
    },

    data() { 
        return {
            tableData: null
        };
    },

    computed: {
        fields() {
            return Object.values(this.entity.queryFields);
        },
        item() {
            const items = this.tableData?.items;
            if(!items?.length) {
                return null;
            }
            return items[0];
        }
    },

    watch: {
        itemId: {
            immediate: true,
            async handler() {
                await this.refresh();
            }
        }
    },

    methods: {
        getCellComponent(fieldKey) {
            return getCellComponent(this.entityKey, fieldKey);
        },
        getCellOptions(fieldKey) {
            return buildCellOptions(this.tableData, fieldKey);
        },
        async refresh() {
            if(!this.itemId) {
                this.tableData = null
                return;
            }

            const filter = equal("id", this.itemId, "int");
            this.tableData = await getTableData(this.entityKey, { filter }, true);
        }
    }
}
</script>

<style scoped>
label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    font-weight: 700;
}
</style>