export default {
    camelToKebabCase,
    camelToTitleCase,
    pascalToCamelCase,
    isNullOrWhiteSpace,
    containsNonNumericCharacters,
    trim
};

export function camelToKebabCase(value) {
    if (value === null || value === "") {
        return value;
    }

    return value.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g, 
        (substring, offset) => (offset ? "-" : "") + substring.toLowerCase());
}

export function camelToTitleCase(value) {
    if (value === null || value === "") {
        return value;
    }

    return value
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        .replace(/^./, s => s.toUpperCase());
}

export function pascalToCamelCase(value) {
    if (value === null || value === "") {
        return value;
    }
    return value.charAt(0).toLowerCase() + value.slice(1);
}

export function isNullOrWhiteSpace(value) {
    return value == null || (typeof value === 'string' && value.trim() == ""); 
}

export function containsNonNumericCharacters(value) {
    return !!value.match(/[^$,.\d]/);
}

export function trim(value, character) {
    if (value === null || value === "") {
        return value;
    }
    let start = 0;
    let end = value.length;

    while(start < end && value[start] === character) {
        ++start;
    }

    while(end > start && value[end - 1] === character) {
        --end;
    }

    if(start === 0 && end === value.length) {
        return value;
    }

    return value.substring(start, end);
}

export function trimSuffix(value, suffix) {
    if(!suffix?.length || !value.endsWith(suffix)) {
        return value;
    }
    return value.slice(0, value.length - suffix.length);
}

export function trimPrefix(value, prefix) {
    if(!prefix?.length || !value.startsWith(prefix)) {
        return value;
        
    }
    return value.slice(value.length - prefix.length - 1);
}
