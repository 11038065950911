export function getColorClass(id) {
    return `color-${getColorNumber(id)}`;
}

export function getBackgroundColorClass(id) {
    return `background-color-${getColorNumber(id)}`;
}

function getColorNumber(id) {
    // there are 20 colours in the theme.scss so we return a deterministic number for an item
    // between 1 and 20.
    return (id ?? 0) % 20 + 1;
}

export default { getColorClass, getBackgroundColorClass }
