import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Theme from "@/assets/style/theme.scss";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi",
    },
    theme: {
        dark: false,
        themes: {
            light: Theme,
        },
    },
});
