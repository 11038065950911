import { routeIsPublic } from "@/services/routeService"

const loadOnStartup = [
    "authentication",
    "schema"
];

const loadOnLoginChange = [
    "localisation",
];

const allLoadableStores = [
    ...loadOnStartup,
    ...loadOnLoginChange
];

async function dispatchAll(stores, action, dispatch) {
    const promises = stores
        .map(store => `${store}/${action}`)
        .map(actionPath => dispatch(actionPath, null, { root: true }));

    await Promise.all(promises);
}

const actions = {
    async onStartup({ dispatch }) {
        await dispatchAll(loadOnStartup, "load", dispatch);
    },

    async onLoginChange({ dispatch }, isLoggedIn) {
        const action = isLoggedIn ? "load" : "clear";
        await dispatchAll(loadOnLoginChange, action, dispatch);
    }
};

export const getters = {
    isLoaded: (_state, _getters, rootState) => {
        const stores = routeIsPublic(rootState.router.currentRoute) ? 
            loadOnStartup : 
            allLoadableStores;

        return stores.every(store => rootState[store].isLoaded)
    }
};

export const load = {
    namespaced: true,
    state: { },
    actions,
    getters
}
