import { camelToTitleCase } from "@/services/stringUtility"

export function enumAsSelectList(enumDictionary) {
    // Convert an enum Ie a dict of numbers vs text description to an array suitable for
    // use in a select control ie an array of dicts with two key / value pairs value and text
    return Object.keys(enumDictionary).map(k => {
        return {
            value: k,
            text: camelToTitleCase(k),
        };
    });
}

function enumWithSpaces(enumDictionary) {
    // add spaces in enum values
    return Object.keys(enumDictionary).map(k => {
        return camelToTitleCase(k)
    });
}

function enumLowerWithSpaces(enumDictionary) {
    // add spaces in enum values
    return Object.keys(enumDictionary).map(k => {
        return camelToTitleCase(k).toLowerCase()
    });
}

function reverseEnum(enumDictionary) {
    // Enums are implemented as a key value pair where the key is a human readable name and the
    // value is an integer.But sometimes we need to get the human readable value from the integer
    // to do this we reverse the enum and produce a dictionary where ints are the keys and strings
    // are the values
    return Object.keys(enumDictionary).reduce((result, k) => {
        const v = enumDictionary[k];
        result[v] = camelToTitleCase(k);
        return result;
    }, {});
}

function reverseEnumNoSpaces(enumDictionary) {
    // Enums are implemented as a key value pair where the key is a human readable name and the
    // value is an integer.But sometimes we need to get the human readable value from the integer
    // to do this we reverse the enum and produce a dictionary where ints are the keys and strings
    // are the values
    return Object.keys(enumDictionary).reduce((result, k) => {
        const v = enumDictionary[k];
        result[v] = k;
        return result;
    }, {});
}

export function enumCompare(enumObject, a, b) {
    return getNumberValue(enumObject, a) - getNumberValue(enumObject, b);
}

export function enumEquals(enumObject, a, b) {
    if(a == b) {
        return true;
    }

    return getNumberValue(enumObject, a) === getNumberValue(enumObject, b);
}

export function getNumberValue(enumObject, value) 
{
    if(!isNaN(value))
    {
        return value;
    }

    const normalizedValue = value
        .replace(/ /g, '')
        .toLowerCase();
        
    const isMatch = key => key.toLowerCase() === normalizedValue;

    const matches = Object
        .keys(enumObject)
        .filter(isMatch);

    if(matches.length > 0) {
        return enumObject[matches[0]];
    }

    return null;
}

export default {
    enumAsSelectList,
    enumWithSpaces,
    enumCompare,
    enumEquals,
    getNumberValue,
    reverseEnum,
    reverseEnumNoSpaces,
    enumLowerWithSpaces
};
