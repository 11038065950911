import { getEnum } from "./schemaProvider"
import { hasProperty } from "@/services/objectUtility";

export class FieldDependencyProvider {
    #dependenciesByType;

    constructor(context, useDefault = true) {
        const dependenciesByType = {}

        context.keys().forEach(filename => {
            const file = context(filename);
            
            const dependency = useDefault ? 
                file.default : 
                file;

            if(!dependency.types) {
                throw `Unable to find the 'types' property in ${filename}.`;
            }

            dependency.types.forEach(type => dependenciesByType[type] = dependency);
        });

        this.#dependenciesByType = dependenciesByType;
    }

    getDependency(schemaField) {
        // Entity fields are integers. We want to resolve these as the "entity" type.
        const type = this.getDependencyType(schemaField);
        return (
            this.#dependenciesByType[type] ?? 
            this.#dependenciesByType["default"]);
    }

    getDependencyType(schemaField) {
        if("dependsOn" in schemaField) {
            // If we're looking at a foreign key, then look for a dependency which handles that 
            // specific type, otherwise fall back to the generic "entity" type.
            let entityType = schemaField.dependsOn.type;
            return this.hasEntityDependency(entityType) ? entityType : "entity";
        }
    
        // Remove trailing "?" from nullable types.
        let type = schemaField.type.replace(/\?$/, "");
    
        if(getEnum(type) !== null) {
            return "enum";
        }
        
        return type;
    }
    
    hasEntityDependency(entityType) {
        return hasProperty(this.#dependenciesByType, entityType);
    }
}