import { getEntity } from "./schemaProvider";
import { isEmpty } from "@/services/objectUtility";
import { getFormatter } from "@/formatting";

const labelPropertiesToIgnore = [
    "updatedAt",
    "notes",
    "description"
];

const labelFieldsCache = {};

export function getLabelFields(entityKey) {
    return labelFieldsCache[entityKey] ??
        (labelFieldsCache[entityKey] = buildLabelFields(entityKey));
}

export function getLabel(entityKey, item) {
    if (item == null) {
        return "";
    }

    let values = getLabelFields(entityKey)
        .map(field => getFieldLabel(field, item));

    return buildLabel(values);
}

function getFieldLabel(field, item) {
    let value = item[field.key];

    return (
        (field.label?.prefix ?? "") +
        field.format(value) +
        (field.label?.suffix ?? " "));
}

function buildLabelFields(entityKey) {
    let entity = getEntity(entityKey);

    let queryFields = Object.values(entity.queryFields);
    let fields = queryFields.filter(field => "label" in field);

    // If there are no label fields, then get all the string fields.
    if (!fields.length) {
        if (queryFields.some(e => e.key == "name")) {
            //If there is a name field, no further descriptors are required
            fields = queryFields.filter(e => e.key == "name");
        }
        else {
            fields = queryFields.filter(isLabelField);
        }
    }

    return fields
        .map(field => ({
            ...field,
            // Cache the formatter with the field so we don't need to keep looking it up.
            format: buildFormatter(field)
        }))
}

export function isLabelField(field) {
    return field.type === "string" &&
        !labelPropertiesToIgnore.includes(field.key);
}

function buildFormatter(field) {
    let entity = getEntity(field.type);

    if (entity == null) {
        return getFormatter(field);
    }

    return value => getLabel(field.type, value);
}

function buildLabel(values) {
    return values
        .reduce((result, value) => result + buildLabelPart(value), "")
        .trim();
}

function buildLabelPart(value) {
    return isEmpty(value) ? "" : value;
}

export function toIdLabels(entityKey, items) {
    return items.map(item => ({
        id: item.id,
        label: getLabel(entityKey, item)
    }));
}

export function removeLabelFormatting(field, value) {
    if (!hasLabelFormatting(field)) {
        return value;
    }

    let prefix = field.label?.prefix;
    if (prefix?.length && value.startsWith(prefix)) {
        value = value.slice(prefix.length);
    }

    let suffix = field.label?.suffix;
    if (suffix?.length && value.endsWith(suffix)) {
        value = value.slice(0, value.length - suffix.length);
    }

    return value;
}

function hasLabelFormatting(field) {
    // Check if this field has prefixes or suffixes added.
    return (
        field.label?.prefix?.length ||
        field.label?.suffix?.length);
}
