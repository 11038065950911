import { getLocale } from "@/services/localisation";

export const types = [
    "float",
    "double",
    "decimal"
];

export function format(value) {
    return value?.toLocaleString(getLocale(), { maximumFractionDigits: 2 })
}