<template>
    <div class="d-flex flex-column schema-table">
        <div class="d-flex align-center mb-5">
            <slot name="above">
                <v-spacer />
            </slot>
            
            <schema-excel-export-button 
                v-bind="$attrs"
                class="ml-4"
                :entity-key="entityKey" />

            <app-button v-if="canAdd" class="ml-4" @click="add">
                Add {{ entity.singleTitle }}
            </app-button>
        </div>

        <v-card class="flex-grow-1 d-flex flex-column pa-2">
            <schema-data-table
                ref="schemaDataTable"
                class="flex-grow-1"
                v-bind="$attrs"
                :entity-key="entityKey"
                @edit="edit">
                <template v-slot:extra-actions="{ item }">
                    <slot name="extra-actions" :item="item" />
                </template>
            </schema-data-table>
        </v-card>

        <schema-form-dialog
            :entity-key="entityKey"
            :value="editedItem" 
            v-bind="$attrs"
            @input="onItemSaved" />
    </div>
</template>

<script>
import schemaMixin from "@/features/schemas/mixins/schemaMixin";
import { canAdd } from "@/features/schemas/services/schemaApi";

export default {
    mixins:[schemaMixin],

    data() { 
        return {
            editedItem: null
        };
    },

    computed: {
        canAdd() {
            return canAdd(this.entityKey);
        }
    },

    methods: {
        add() {
            this.editedItem = {};
        },
        edit(item) {
            this.editedItem = item;
        },
        async onItemSaved() {
            this.editedItem = null;
            await this.$refs.schemaDataTable.refresh();
        }
    }
};
</script>
