export function and(filters) {
    if(filters.length === 1) {
        return filters[0];
    }

    return {
        $type: "list",
        comparison: "and",
        value: filters
    };
}

export function or(filters) {
    if(filters.length === 1) {
        return filters[0];
    }

    return {
        $type: "list",
        comparison: "or",
        value: filters
    };
}

export function equal(property, value, type) {
    return {
        $type: type ?? "int",
        property,
        comparison: "equal",
        value
    };
}

export function notEqual(property, value, type) {
    return {
        $type: type ?? "int",
        property,
        comparison: "notEqual",
        value
    };
}

export function lessThan(property, value, type) {
    return {
        $type: type ?? "int",
        property,
        comparison: "lessThan",
        value
    };
}

export function lessThanOrEqual(property, value, type) {
    return {
        $type: type ?? "int",
        property,
        comparison: "lessThanOrEqual",
        value
    };
}

export function greaterThan(property, value, type){
    return {
        $type: type ?? "int",
        property,
        comparison: "greaterThan",
        value
    };
}

export function greaterThanOrEqual(property, value, type){
    return {
        $type: type ?? "int",
        property,
        comparison: "greaterThanOrEqual",
        value
    };
}

export function startsWith(property, value, type){
    return {
        $type: type ?? "string",
        property,
        comparison: "startsWith",
        value
    };
}

export function any(property, filter) {
    return {
        $type: "filter",
        property,
        comparison: "any",
        value: filter
    };
}

export function all(property, filter){
    return {
        $type: "filter",
        property,
        comparison: "all",
        value: filter
    };
}

export function anyValue(property, values, type) {
    let filters = values?.map(value => equal(property, value, type));
    if(filters == null || filters.length === 0) 
    {
        return null;
    }
    return or(filters);
}

export default { 
    and, 
    or, 
    equal, 
    notEqual, 
    lessThan, 
    lessThanOrEqual, 
    greaterThan, 
    greaterThanOrEqual, 
    startsWith, 
    any, 
    all,
    anyValue
}