<template>
    <v-text-field
        type="time"
        v-bind="$attrs"
        :value="value"
        :label="field.title"
        :rules="rules"
        :required="required" 
        v-on="$listeners" />
</template>

<script>
import formFieldMixin from "./formFieldMixin";

export default {
    types: ["time"],
    mixins: [formFieldMixin]
};
</script>
