import qs from "qs";

function decoder(text, decoder, charset) {
    const textWithoutPlus = text.replace(/\+/g, " ");
    if (charset === "iso-8859-1") {
        // unescape never throws, no try...catch needed:
        return textWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
    }

    if (/^(\d+|\d*\.\d+)$/.test(text)) {
        return parseFloat(text);
    }

    const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
    };
    
    if (text in keywords) {
        return keywords[text];
    }

    // utf-8
    try {
        return decodeURIComponent(textWithoutPlus);
    } catch (e) {
        return textWithoutPlus;
    }
}

export function parseQuery(query) {
    return qs.parse(query, { decoder });
}

export function stringifyQuery(query) {
    const result = qs.stringify(query, { encode: false });
    return result ? "?" + result : "";
}

export default {
    parseQuery,
    stringifyQuery
};