import api from "@/features/schemas/services/schemaApi"
import { canAdd, canEdit, canDelete } from '@/features/schemas/services/schemaApi';
import { getComponent } from "@/features/schemas/formFields";
import schemaMixin from "@/features/schemas/mixins/schemaMixin";

export default {
    mixins: [ schemaMixin ],
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        fixedValues: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isValid: false,
            isSaving: false,
            item: {}
        };
    },

    computed: {
        fields() {
            return Object
                .values(this.entity.commandFields)
                .filter(e => 
                    e.key !== "id" &&
                    // Exclude any things which are fixed values.
                    (!this.fixedValues || !Object.keys(this.fixedValues).includes(e.key)));
        },
        form() {
            return this.$refs.form;
        },
        isEdit() {
            return !!this.value?.id;
        },
        canSave() {
            return this.isEdit ? 
                canEdit(this.entityKey) : 
                canAdd(this.entityKey);
        },
        canCancel(){ 
            return this.$listeners?.cancel;
        },
        canDelete() {
            return canDelete(this.entityKey) && this.isEdit;
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.item = JSON.parse(JSON.stringify(value));
                }
                else {
                    this.item = {};
                }
            }
        }
    },

    methods: {
        getComponent,
        async save() {
            if (this.isSaving || !this.form.validate()) {
                return;
            }

            this.isSaving = true;
            
            try {
                const model = {...this.item, ...this.fixedValues};
                this.item = await api.save(this.entityKey, model, true);
                this.$emit("input", this.item);
            }
            finally {
                this.isSaving = false;
            }
        },
        async deleteItem() {
            if (this.isSaving) {
                return;
            }
            
            if(!confirm("Are you sure you want to delete this item?")) {
                return;
            }

            this.isSaving = true;
            
            try {
                await api.delete(this.entityKey, this.value?.id);
                this.$emit("input", null);
            }
            finally {
                this.isSaving = false;
            }
        }
    }
}