/**
 * Returns a promise that requires a period of inactivity before awaiting the callback.
 * @param {function} asyncCallback A function returning a promise to run after the delay.
 * @param {number} delay The inactive time in milliseconds before running the callback.
*/
export function debounce (asyncCallback, delay = 1000) { 
    let waitTask; 

    return async function(...args) { 
        waitTask?.cancel();       
        waitTask = wait(delay);
        if(await waitTask.promise) {
            await asyncCallback.apply(this, args);
        }       
    } 
}

function wait (delay) { 
    let cancel, timeout;

    // This is a promise that will resolve true after the delay, or false if it's cancelled.
    let promise = new Promise((resolve) => { 
        cancel = () => {
            clearTimeout(timeout);
            resolve(false);
        }
        timeout = setTimeout(() => resolve(true), delay) 
    });

    return { promise, cancel } 
}

/** 
 * Returns a function which runs the callback immediately but requires a period of inactity before
 * it can be run again.
 * @param {function} callback The function to run immediately.
 * @param {number} delay The inactive time in milliseconds before the action can be run again.
*/
export function leadingDebounce(callback, delay = 1000) {
    let timeout;

    return function(...args) {
        if (!timeout) {
            callback.apply(this, args);
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => timeout = undefined, delay);
    };
}

export default { debounce, leadingDebounce };