import { getEntity } from "@/features/schemas/services/schemaProvider";

export default {
    props: {
        entityKey: {
            type: String,
            default: null
        }
    },
    computed: {
        entity() {
            return getEntity(this.entityKey);
        }
    }
}