import { get } from "@/services/apiService"

function getDefaultState() {
    return {
        timeZone: "",
        cultureCode: null,
        isLoaded: false
    }
}

const state = getDefaultState;

const actions = {
    async load({ commit, rootState }) {
        let user = rootState.authentication.user;

        const culture = await get("Cultures", user.cultureId);
        const timeZone = await get("TimeZones", user.timeZoneId);

        commit("set", { culture, timeZone });
    },
    clear({ commit }) {
        commit("clear");
    }
};

const mutations = {
    set(state, { culture, timeZone }) {
        state.cultureCode = culture.code;
        state.timeZone = timeZone.name;
        state.isLoaded = true;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    }
};

export const localisation = {
    namespaced: true,
    state,
    actions,
    mutations
};
