let appTableView = () =>
    import(
        /* webpackChunkName: "appTableView" */ "@/features/schemas/views/SchemaTableView.vue");

export default [
    {
        path: "/activities",
        name: "activities",
        staticProps: { entityKey: "activity" },
    },
    {
        path: "/activity-steps",
        name: "activitySteps",
        staticProps: { entityKey: "activityStep" },
    },
    {
        path: "/addresses",
        name: "addresses",
        staticProps: { entityKey: "address" },
    },
    {
        path: "/claims",
        name: "claims",
        staticProps: { entityKey: "claim" },
    },
    {
        path: "/clubs",
        name: "clubs",
        staticProps: { entityKey: "club" },
    },
    {
        path: "/club-interests",
        name: "clubInterests",
        staticProps: { entityKey: "clubInterest" },
    },
    {
        path: "/club-organisers",
        name: "clubOrganisers",
        staticProps: { entityKey: "clubOrganiser" },
    },
    {
        path: "/countries",
        name: "countries",
        staticProps: { entityKey: "country" },
    },
    {
        path: "/cultures",
        name: "cultures",
        staticProps: { entityKey: "culture" },
    },
    {
        path: "/events",
        name: "events",
        staticProps: { entityKey: "event" },
    },
    {
        path: "/event-attendees",
        name: "eventAttendees",
        staticProps: { entityKey: "eventAttendee" },
    },
    {
        path: "/event-programs",
        name: "eventPrograms",
        staticProps: { entityKey: "eventProgram" },
    },
    {
        path: "/files",
        name: "files",
        staticProps: { entityKey: "file" },
    },
    {
        path: "/interests",
        name: "interests",
        staticProps: { entityKey: "interest" },
    },
    {
        path: "/people",
        name: "people",
        staticProps: { entityKey: "person" },
    },
    {
        path: "/person-interests",
        name: "personInterests",
        staticProps: { entityKey: "personInterest" },
    },
    {
        path: "/person-programs",
        name: "personPrograms",
        staticProps: { entityKey: "personProgram" },
    },
    {
        path: "/person-sessions",
        name: "personSessions",
        staticProps: { entityKey: "personSession" },
    },
    {
        path: "/programs",
        name: "programs",
        staticProps: { entityKey: "program" },
    },
    {
        path: "/roles",
        name: "roles",
        staticProps: { entityKey: "role" },
    },
    {
        path: "/role-claims",
        name: "roleClaims",
        staticProps: { entityKey: "roleClaim" },
    },
    {
        path: "/sessions",
        name: "sessions",
        staticProps: { entityKey: "session" },
    },
    {
        path: "/settings",
        name: "settings",
        staticProps: { entityKey: "setting" },
    },
    {
        path: "/time-zones",
        name: "timeZones",
        staticProps: { entityKey: "timeZone" },
    },
    {
        path: "/users",
        name: "users",
        staticProps: { entityKey: "user" },
    },
    {
        path: "/user-roles",
        name: "userRoles",
        staticProps: { entityKey: "userRole" },
    },
].map(e => {
    return { ...e, component: appTableView };
});
