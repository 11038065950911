<template>
    <v-dialog
        v-bind="$attrs"
        persistent
        max-width="500px"
        v-on="$listeners">
        <v-form @submit.prevent="ok">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ title }}</span>
                </v-card-title>
                <v-card-text>
                    <slot />
                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1" />
                    <v-btn type="submit" color="quaternary" text>
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            default: () => "An error has occurred"
        }
    },
    methods: {
        ok() {
            this.$emit("input", false);
        }
    }
};
</script>
