<template>
    <v-btn
        class="px-0 text-none app-btn"
        :color="color"
        rounded
        elevation="2"
        v-bind="$attrs"
        v-on="$listeners">
        <v-icon v-if="icon" class="app-btn-icon">
            {{ icon }}
        </v-icon>
        <span class="px-5 flex-grow-1"><slot /></span>
    </v-btn>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: "primary"
        },
        icon: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../assets/style/theme.scss";

.app-btn-icon {
    background-color: $color-secondary;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    font-size: 22px;
    margin-right: -5px;
}

.app-btn.v-size--default {
    color: #ffffff;
    text-transform: unset;
    font-size: 1rem;
}

</style>
