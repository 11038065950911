import { call, canGetMany } from "./schemaApi";
import { byId, distinctBy } from "@/services/arrayUtility";
import { getEntity, getQueryField } from "./schemaProvider";
import { getLabelFields } from "./labeller";
import cells from "@/features/schemas/tableCells";
import { isNullOrWhiteSpace } from "@/services/stringUtility";
import { hasProperty } from "@/services/objectUtility";

function getDistinctTypes(foreignKeys) {
    return [...new Set(foreignKeys.map(f => f.type))];
}

function getDistinctIds(foreignKeys, type) {
    let ids = foreignKeys
        .filter(f => f.type === type)
        .map(f => f.ids)
        .flat()
        .filter(id => id !== null);

    return [...new Set(ids)];
}

function getForeignKeys(entityKey, listResult) {
    let fields = getEntity(entityKey).queryFields;
    let fieldNames = Object.keys(fields);

    // Get foreign keys from this call by looking at the "depends on" attribute.
    return fieldNames
        .map(f => fields[f])
        .filter(field => "dependsOn" in field)
        .map(field => ({
            key: field.key,
            type: field.dependsOn.type,
            ids: listResult.items.map(item => item[field.key])
        }));
}

async function addLookup(type, ids, lookups) {
    let lookup = await getLookup(type, ids);
    if (lookup != null) {
        lookups[type] = lookup;
    }
}

async function getLookup(type, ids) {
    if(ids.length) {
        let list = await call(type, "getMany", ids);
        return byId(list.items);
    }
    return null;
}

async function getForeignKeyLookups(entityKey, result) {
    let foreignKeys = getForeignKeys(entityKey, result);
    let types = getDistinctTypes(foreignKeys)
        // Don't include any types we cannot list
        .filter(canGetMany);

    let lookups = {};
    let promises = [];

    for (let i = 0; i < types.length; i++) {
        let type = types[i];
        let ids = getDistinctIds(foreignKeys, type);
        promises.push(addLookup(type, ids, lookups));
    }

    // Get all the lookups in parallel.
    await Promise.all(promises);
    return lookups;
}

export function getHeaders(entityKey) {
    const entity = getEntity(entityKey);
    const fields = Object
        .values(entity.queryFields)
        .filter(f => 
            // Hide the id field, unless it's explicitly part of a label.
            (f.key !== "id" || hasProperty(f, "label")) && 
            // Don't include list columns.
            f.type !== "list" &&
            // If the field depends on an entity, make sure we can get the lookup.
            (!f.dependsOn || canGetMany(f.dependsOn.type)))
        .map(f=> ({text: f.title, value: f.key}));

    // If there are multiple fields with more than one value, return the first one.
    // TODO: Prefer inline data to lookups
    return distinctBy(fields, f => f.text);
}

export async function getTableData(entityKey, model, includeLookups) {
    let result = await call(entityKey, "list", model);
    result.entityKey = entityKey;
    if(includeLookups) {
        result.lookups = await getForeignKeyLookups(entityKey, result);
    }
    result.headers = getHeaders(entityKey);
    return result;
}

export function getCellComponent(entityKey, fieldKey) {
    let field = getQueryField(entityKey, fieldKey);
    return cells.getComponent(field);
}

export function buildCellOptions(tableData, fieldKey) {
    let field = getQueryField(tableData.entityKey, fieldKey);

    let options = {
        field
    }   

    if(field?.dependsOn) {
        options.entityKey = field.dependsOn.type;
        options.lookup = tableData.lookups[field.dependsOn.type];
    }

    return options;
}

export function applyForeignKeySorting(entityKey, sortBy) {
    // If we're sorting by a foreign key in a table, then we want to sort by the displayed value, 
    // and not the Id. To do this, we want to look at the associated label fields and sort by the 
    // appropriate path to those fields.

    // If sortBy is a property path (contains '.'), assume the caller knows what they are chasing
    // and do not modify the sorting.
    if(isNullOrWhiteSpace(sortBy) || sortBy.includes(".")) {
        return sortBy;
    }

    // Get the relevant label field.
    const field = getQueryField(entityKey, sortBy);
    if(field == null || !field.dependsOn?.type) {
        return sortBy;
    }

    const labelFields = getLabelFields(field.dependsOn.type);
    if(labelFields.length < 1) {
        return sortBy;
    }

    // Relying on a convention where the navigation path is named the same as the foreign key, 
    // minus the 'Id'. If this proves unreliable, include the navigation path as part of the
    // dependsOn attribute.
    if(sortBy.toLowerCase().endsWith("id"))
    {
        sortBy = sortBy.substring(0, sortBy.length - 2);
    }

    // TODO: allow for sorting by all label fields, rather than just the first one.
    // This requires a change to the API.
    return `${sortBy}.${labelFields[0].key}`;
}

export default {
    getHeaders,
    getTableData,
    getCellComponent,
    buildCellOptions
}
