import { DateTime } from "luxon";
import { getTimeZone, getLocale } from "@/services/localisation";
import { isNullOrWhiteSpace } from "./stringUtility";

export function formatIsoAsDate(isoDate) {
    if (isoDate === null || typeof isoDate === "undefined") {
        return "";
    }

    const localDateTime = parseIso(isoDate)
        .setZone(getTimeZone());

    return formatDate(localDateTime);
}

export function addDays(isoDate, days) {
    return DateTime
        .fromISO(isoDate, { setZone: true })
        .plus({ days: days })
        .toISO();
}

export function toIsoOffset(offset) {
    if (offset === 0) {
        return "Z";
    }

    let hours = parseInt(Math.abs(offset / 60));
    let minutes = Math.abs(offset % 60);
    let prefix = offset > 0 ? "+" : "-";

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    return prefix + hours + ":" + minutes;
}

export function isDate(value) {
    return (
        typeof value === "string" &&
        parseDate(value).isValid
    );
}

export function dateTimesEqual(dateTime1, dateTime2) {
    if(dateTime1 === null && dateTime2 === null) {
        return true;
    }

    if(dateTime1 === null || dateTime2 === null) {
        return false;
    }

    // This method is more reliable than equals() because we lose time zone information when
    // serializing then deserializing. This causing equals() to return false when we want it to
    // return true.
    return dateTime1.toISO() === dateTime2.toISO();
}

export function now() {
    return DateTime.local(getLocalizationOptions());
}

export function today() {
    return now()
        .startOf("day");
}

export function tomorrow() {
    return today()
        .plus({ days: 1 });
}

export function startOfWeek() {
    // TODO: use configured week start
    return now()
        .startOf("week");
}

export function endOfWeek() {
    // TODO: use configured week start
    return now()
        .startOf("week")
        .plus({ days: 6 });
}

export function startOfMonth() {
    return now()
        .startOf("month");
}

export function endOfMonth() {
    let daysInMonth = now().daysInMonth;
    return now()
        .startOf("month")
        .plus({ days: daysInMonth - 1 });
}

function getLocalizationOptions() {
    return {
        zone: getTimeZone(),
        locale: getLocale()
    };
}

export function formatDate(dateTime, format = DateTime.DATE_SHORT) {
    return dateTime
        ?.setLocale(getLocale())
        .toLocaleString(format);
}

export function formatTime(dateTime) {
    return dateTime
        ?.setLocale(getLocale())
        .toLocaleString(DateTime.TIME_SIMPLE)
}

export function parseDate(text) {
    if(isNullOrWhiteSpace(text)) {
        return null;
    }
    return DateTime.fromFormat(text, "D", getLocalizationOptions());
}

export function parseTime(text) {
    if(isNullOrWhiteSpace(text)) {
        return null;
    }

    text = text
        .trim()
        // Ensure that there's always a space before am or pm.
        .replace(/(am|AM|pm|PM)/g, " $1")
        // Replace multiple spaces with a single space.
        .replace(/[ ]+/g, " ");

    let time = DateTime.fromFormat(text, "t", getLocalizationOptions());

    // If we don't have a valid date for the locale (e.g. missing am/pm) then attempt to parse it
    // in 24 hour format. For some locales (e.g. fr-FR), 24 hour is the default. In these cases,
    // this step is redundant but is left here for simplicity.
    if(!time.isValid){
        let time24 = DateTime.fromFormat(text, "T", getLocalizationOptions());
        if(time24.isValid) {
            return time24;
        }
    }

    return time;
}

export function parseIso(isoDate) {
    if(isNullOrWhiteSpace(isoDate)) {
        return null;
    }
    return DateTime.fromISO(isoDate, {
        setZone: true,
        locale: getLocale()
    });
}

export function localize(dateTime) {
    return dateTime
        .setZone(getTimeZone())
        .setLocale(getLocale())
}

export function getDateFormatString() {
    let dateTime = parseIso('9876-02-01T00:00:00.000Z');

    return dateTime
        .toLocaleString(DateTime.DATE_SHORT)
        .replace(/01/g, "dd")
        .replace(/1/g, "d")
        .replace(/02/g, "MM")
        .replace(/2/g, "M")
        .replace(/9876/g, "yyyy")
        .replace(/76/g, "yy");
}

export function getTimeFormatString() {
    let dateTime = parseIso('0001-01-01T14:31:56.000Z');

    return dateTime
        .toLocaleString(DateTime.TIME_SIMPLE)
        .replace(/14/g, "HH")
        .replace(/02/g, "hh")
        .replace(/2/g, "h")
        .replace(/31/g, "mm")
        .replace(/56/g, "ss")
        .replace(/pm|PM/g, "am/pm");
}

export function getIsoDateOnly(isoDateTime) {
    return isoDateTime.split('T')[0]
        + 'T00:00:00+'
        + isoDateTime.split('+')[1];
}

export function getIsoTimeOnly(isoDateTime) {
    let dateTime = parseIso(isoDateTime);
    return dateTime?.toISOTime();
}

export function joinIsoDateAndTime(isoDate, isoTime) {
    if (isoDate === null || isoTime === null) {
        return null;
    }
    let dateOnly = isoDate.split("T")[0];

    let offsetOperator = isoDate.includes("+") ? "+" : "-";
    let offset = offsetOperator + isoDate.split(offsetOperator)[1];

    let timeOnly = isoTime.split(offsetOperator)[0];

    return dateOnly + "T" + timeOnly + offset;
}
