import Vue from "vue";
import { isEmpty } from "@/services/objectUtility";
import { handleError } from "@/services/errorUtility"

export default Vue.mixin({
    methods: {
        isEmpty,
        handleError,
        hasProperty(value, property) {
            return Object.prototype.hasOwnProperty.call(value, property)
        },
        objectValuesToArray(value) {
            return Object.keys(value).map((key) => value[key]);
        },
    },
});
