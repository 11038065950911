<template>
    <app-dialog :value="!!value" @keydown.esc="$emit('input', null)">
        <schema-form
            :key="formKey"
            :entity-key="entityKey"
            :value="value"
            v-bind="$attrs"
            v-on="$listeners"
            @cancel="$emit('input', null)" />
    </app-dialog>
</template>

<script>
import schemaMixin from "@/features/schemas/mixins/schemaMixin";

export default {
    mixins:[schemaMixin],
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            formKey: 0
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if(value) {
                    this.formKey++;
                }
            }
        }
    }
}
</script>

